<template>
  <div>
    <div class="text-center mb-2 mt-1">
      <el-radio-group v-model="field.is_global_variable">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>

    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Rows">
            <el-input-number
              v-model="tableProperties.rows"
              :min="1"
              @change="updateTableProperties"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Columns">
            <el-input-number
              v-model="tableProperties.columns"
              :min="1"
              @change="updateTableProperties"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Border Size">
            <el-input-number
              v-model="tableProperties.borderSize"
              @change="updateTableProperties"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Cell Spacing">
            <el-input-number
              v-model="tableProperties.cellSpacing"
              @change="updateTableProperties"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Cell Padding">
            <el-input-number
              v-model="tableProperties.cellPadding"
              @change="updateTableProperties"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Global Table Variable">
            <el-select
              v-model="field.global_variable_id"
              @change="setGlobalVariable"
            >
              <el-option
                v-for="(globalVariable, index) of allGlobalVariables"
                :key="index"
                :label="globalVariable.label"
                filterable
                :value="globalVariable._id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "table-properties",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
  },

  props: ["field"],

  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },

  mounted() {
    this.fieldData = this.field;
    this.fetchGlobalVariables();
  },

  data() {
    return {
      fieldData: null,
      tableProperties: {
        rows: "",
        columns: "",
        borderSize: "",
        cellPadding: "",
        cellSpacing: "",
      },
    };
  },

  methods: {
    async fetchGlobalVariables() {
      const params = {
        get_all: true,
        input_type: this.field.input_type,
      };
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },

    setGlobalVariable() {
      const globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    },

    updateTableProperties() {
      this.fieldData["tableProperties"] = this.tableProperties;
      this.$emit("update-table-properties", this.fieldData);
    },
  },
};
</script>

<style lang="scss"></style>
